import { createRouter, createWebHashHistory } from 'vue-router'
// import Home from '../views/Home.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Index'),
    children: [
      /*{
        path: '/chip/list',
        name: 'chipList',
        component: () => import('@/views/chip-manager/ChipList')
      },*/
      {
        path: '/chip/add',
        name: 'chipAdd',
        component: () => import('@/views/chip-manager/batch/ChipBatchAdd')
      },
      {
        path: '/chip/batch/list',
        name: 'chipbatchlist',
        component: () => import('@/views/chip-manager/batch/ChipBatchList')
      },
      {
        path: '/chip/deliver/apply',
        name: '出库申请',
        component: () => import('@/views/chip-manager/deliver/Apply')
      },
      {
        path: '/chip/batch/out',
        name: '出库申请',
        component: () => import('@/views/chip-manager/OutList')
      },
      {
        path: '/sys/feedback',
        name: '意见反馈',
        component: () => import('@/views/Feedback')
      },
      {
        path: '/case/list',
        name: 'Case管理',
        component: () => import('@/views/core/base/case/CaseBaseList')
      },
      {
        path: '/case/add/:id?/:editType?/:editTitle?',
        name: '新增Case',
        component: () => import('@/views/core/base/case/CaseBaseDetail')
      },
      {
        path: '/case/sku/list',
        name: 'Sku管理',
        component: () => import('@/views/core/base/sku/SkuBaseList')
      },
      {
        path: '/case/sku/add/:id?/:editType?/:editTitle?',
        name: '新增sku',
        component: () => import('@/views/core/base/sku/SkuBaseDetail')
      },
      {
        path: '/case/sku/item/list',
        name: 'Item管理',
        component: () => import('@/views/core/base/item/ItemBaseList')
      },
      {
        path: '/case/sku/item/add/:id?/:editType?/:editTitle?',
        name: '新增item',
        component: () => import('@/views/core/base/item/ItemBaseDetail')
      },
      {
        path: '/ads/banner',
        name: 'Banner管理',
        component: () => import('@/views/banner/BannerList')
      },
      {
        path: '/ads/banner/add/:id?/:editType?/:editTitle?',
        name: '新增banner',
        component: () => import('@/views/banner/BannerDetail')
      },
      {
        path: '/sys/dictitem',
        name: '字典管理',
        component: () => import('@/views/dict/DictItemList')
      },
      {
        path: '/sys/dictitem/add/:id?',
        name: '新增字典',
        component: () => import('@/views/dict/DictItemDetail')
      },
      {
        path: '/sys/dict/:id?',
        name: '字典数据',
        component: () => import('@/views/dict/DictList')
      },
      {
        path: '/sys/dict/add',
        name: '新增字典数据',
        component: () => import('@/views/dict/DictDetail')
      },
      {
        path: '/app/msg',
        name: '消息管理',
        component: () => import('@/views/app/msg/MsgList')
      },
      {
        path: '/app/msg/add',
        name: '新增消息',
        component: () => import('@/views/app/msg/MsgDetail')
      },
      {
        path: '/app/VersionList',
        name: '版本升级',
        component: () => import('@/views/app/VersionList')
      },
      {
        path: '/business/case/list',
        name: '项目运营管理',
        component: () => import('@/views/core/business/case/CaseList')
      },
      {
        path: '/business/case/sort/list',
        name: '项目排序管理',
        component: () => import('@/views/core/business/case/CaseSortList')
      },
      {
        path: '/business/case/sku/list',
        name: 'SKU运营管理',
        component: () => import('@/views/core/business/sku/SkuList')
      },
      {
        path: '/business/case/sku/add/:id?/:editType?/:editTitle?',
        name: '新增sku运营',
        component: () => import('@/views/core/business/sku/SkuDetail')
      },

      {
        path: '/business/business/case/add/:id?',
        name: '新增运营项目',
        component: () => import('@/views/core/business/case/CaseDetail')
      },


      {
        path: '/business/itemStore/list',
        name: '实物管理',
        component: () => import('@/views/core/business/itemStore/ItemStoreList')
      },

      {
        path: '/business/itemStore/detail/:id?/:editType?/:editTitle?/:suitType?',
        name: '实物详情',
        component: () => import('@/views/core/business/itemStore/ItemStoreDetail')
      },

      {
        path: '/business/skuStore/list',
        name: '实物套件管理',
        component: () => import('@/views/core/business/skuStore/SkuStoreList')
      },

      {
        path: '/business/skuStore/detail/:id?/:editType?/:editTitle?/:suitType?',
        name: '实物套件详情',
        component: () => import('@/views/core/business/skuStore/SkuStoreDetail')
      },

      {
        path: '/chip/bind/list',
        name: '新增运营case',
        component: () => import('@/views/chip-manager/bind/BindList')
      },

      {
        path: '/chip/store/metadata/list',
        name: '标签数据',
        component: () => import('@/views/chip/store/metadata/MetadataList')
      },
      {
        path: '/chip/store/bind/list',
        name: '载体绑定',
        component: () => import('@/views/chip/store/bind/BindList')
      },
      {
        path: '/chip/store/push/list',
        name: '芯片入库',
        component: () => import('@/views/chip/store/push/PushList')
      },
      {
        path: '/chip/store/pop/list',
        name: '芯片出库',
        component: () => import('@/views/chip/store/pop/PopList')
      },
      {
        path: '/chip/list',
        name: '芯片列表',
        component: () => import('@/views/chip/ChipList')
      },
      //基础配置
      {
        path: '/base/factory',
        name: '厂商管理',
        component: () => import('@/views/base/FactoryList')
      },
      {
        path: '/base/supplier',
        name: '供应商管理',
        component: () => import('@/views/base/SupplierList')
      },
      {
        path: '/base/ip',
        name: 'IP管理',
        component: () => import('@/views/base/IpList')
      },
      {
        path: '/base/studio',
        name: '工作室管理',
        component: () => import('@/views/base/StudioList')
      },
      {
        path: '/base/publisher',
        name: '发行方管理',
        component: () => import('@/views/base/PublisherList')
      },
      {
        path: '/base/designer',
        name: '设计师管理',
        component: () => import('@/views/base/DesignerList')
      },
      {
        path: '/base/scale',
        name: '比例管理',
        component: () => import('@/views/base/ScaleList')
      },
      {
        path: '/base/size',
        name: '尺寸管理',
        component: () => import('@/views/base/SizeList')
      },
      {
        path: '/base/material',
        name: '材质管理',
        component: () => import('@/views/base/MaterialList')
      },
      {
        path: '/base/figure',
        name: '形象类型管理',
        component: () => import('@/views/base/FigureList')
      },
      {
        path: '/base/model',
        name: '造型属性管理',
        component: () => import('@/views/base/ModelList')
      },




      {
        path: '/order/list',
        name: '订单列表',
        component: () => import('@/views/order/OrderList')
      },
      {
        path: '/order/detail/:orderSn?/:editType?/:editTitle?/:suitType?',
        name: '订单详情',
        component: () => import('@/views/order/OrderDetail')
      },
      {
        path: '/order/print/:queryForm?',
        name: '发货单',
        component: () => import('@/views/order/OrderPrint')
      },
      {
        path: '/order/tradeList',
        name: '交易流水',
        component: () => import('@/views/order/TradeList')
      },
      {
        path: '/order/expressList',
        name: '物流管理',
        component: () => import('@/views/order/ExpressList')
      },

      {
        path: '/user/list',
        name: '用户列表',
        component: () => import('@/views/user/UserList')
      },

      {
        path: '/user/detail/:userId?/:editType?/:editTitle?/:suitType?',
        name: '用户详情',
        component: () => import('@/views/user/UserDetail')
      },
      {
        path: '/user/walletList',
        name: '用户卡包',
        component: () => import('@/views/user/WalletList')
      },
      {
        path: '/user/walletDetail/:walletId?/:editType?/:editTitle?/:suitType?',
        name: '卡包详情',
        component: () => import('@/views/user/WalletDetail')
      },
      {
        path: '/user/transHistoryList',
        name: '证书转让记录',
        component: () => import('@/views/user/TransHistoryList')
      },

      {
        path: '/user/transHistoryDetail/:transId?/:editType?/:editTitle?/:suitType?',
        name: '证书转让详情',
        component: () => import('@/views/user/TransHistoryDetail')
      },

      {
        path: '/order/serviceOrder/refundList',
        name: '退款管理',
        component: () => import('@/views/order/RefundList')
      },
      {
        path: '/order/serviceOrder/refundDetail/:id?/:editType?/:editTitle?',
        name: '退款详情',
        component: () => import('@/views/order/RefundDetail')
      },

      {
        path: '/order/serviceOrder/backExpressList',
        name: '退货管理',
        component: () => import('@/views/order/BackExpressList')
      },
      {
        path: '/order/serviceOrder/backExpressDetail/:id?/:editType?/:editTitle?',
        name: '退货详情',
        component: () => import('@/views/order/BackExpressDetail')
      },

      {
        path: '/notice/templateList',
        name: '消息模板管理',
        component: () => import('@/views/notice/TemplateList')
      },
      {
        path: '/notice/templateDetail/:id?/:editType?/:editTitle?',
        name: '消息模板详情',
        component: () => import('@/views/notice/TemplateDetail')
      },

      {
        path: '/notice/noticeList',
        name: '消息列表',
        component: () => import('@/views/notice/NoticeList')
      },

      {
        path: '/secondaryTrade/storeList',
        name: '商品列表',
        component: () => import('@/views/secondaryTrade/StoreList')
      },
      {
        path: '/secondaryTrade/resellList',
        name: '出售列表',
        component: () => import('@/views/secondaryTrade/ResellList')
      },
      {
        path: '/secondaryTrade/resellDetail/:id?/:editType?/:editTitle?',
        name: '转卖详情',
        component: () => import('@/views/secondaryTrade/ResellDetail')
      },

      {
        path: '/secondaryTrade/seekList',
        name: '求购列表',
        component: () => import('@/views/secondaryTrade/SeekList')
      },
      {
        path: '/secondaryTrade/seekDetail/:id?/:editType?/:editTitle?',
        name: '求购详情',
        component: () => import('@/views/secondaryTrade/SeekDetail')
      },

      {
        path: '/secondaryTrade/order/list',
        name: '二手订单',
        component: () => import('@/views/order/OrderList')
      },
      {
        path: '/secondaryTrade/order/detail/:orderSn?/:editType?/:editTitle?/:suitType?/:firstSell?',
        name: '二手订单详情',
        component: () => import('@/views/order/OrderDetail')
      },


      {
        path: '/secondaryTrade/refundList',
        name: '二手退款列表',
        component: () => import('@/views/secondaryTrade/RefundList')
      },
      {
        path: '/secondaryTrade/refundDetail/:id?/:editType?/:editTitle?',
        name: '二手退款详情',
        component: () => import('@/views/secondaryTrade/RefundDetail')
      },

      {
        path: '/secondaryTrade/backExpressList',
        name: '二手退货列表',
        component: () => import('@/views/secondaryTrade/BackExpressList')
      },
      {
        path: '/secondaryTrade/backExpressDetail/:id?/:editType?/:editTitle?',
        name: '二手退货详情',
        component: () => import('@/views/secondaryTrade/BackExpressDetail')
      },

      {
        path: '/statistics/trade',
        name: '交易统计',
        component: () => import('@/views/statistics/TradeDetail')
      },
      {
        path: '/statistics/store',
        name: '商品统计',
        component: () => import('@/views/statistics/StoreDetail')
      },
      {
        path: '/community/category',
        name: '分类管理',
        component: () => import('@/views/community/Category')
      },
      {
        path: '/community/topic',
        name: '话题管理',
        component: () => import('@/views/community/TopicList')
      },
      {
        path: '/community/topic/add/:id?',
        name: '新增话题',
        component: () => import('@/views/community/TopicAdd')
      },
      {
        path: '/community/topic/:id',
        name: '话题详情',
        component: () => import('@/views/community/TopicDetail')
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/Login')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
