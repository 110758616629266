import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import Storage from './utils/sessionStorage.js'

// import axios from 'axios';

// /*第一层if判断生产环境和开发环境*/
// if (process.env.NODE_ENV === 'production') {
//     /*第二层if，根据.env文件中的VUE_APP_FLAG判断是生产环境还是测试环境*/
//     if (process.env.VUE_APP_FLAG === 'pro') {
//         //production 生产环境
//         axios.defaults.baseURL = 'http://api.xinggeyun.com';
//     } else {
//         //test 测试环境
//         axios.defaults.baseURL = 'http://192.168.0.152:8102';
//     }
// } else {
//     //serve 开发环境
//     axios.defaults.baseURL = 'http://localhost:91001';
// }
import print from 'vue3-print-nb'


const app = createApp(App)
app.use(ElementPlus)
app.use(print)
app.use(Storage)

app._context.components.ElDialog.props.closeOnClickModal.default = false // 修改 el-dialog 默认点击遮照为不关闭

app.use(router).mount('#app')
