let baseUnique = ":" + localStorage.getItem("token");

let Order_OrderListSearch = "Order_OrderListSearch" + baseUnique;
let Order_ExpressListSearch = "Order_ExpressListSearch" + baseUnique;
let Order_BackExpressListSearch = "Order_BackExpressListSearch" + baseUnique;
let Order_RefundListSearch = "Order_RefundListSearch" + baseUnique;
let Order_TradeListSearch = "Order_TradeListSearch" + baseUnique;
let Banner_BannerListSearch = "Banner_BannerListSearch" + baseUnique;
let App_MsgListSearch = "App_MsgListSearch" + baseUnique;
let Base_DesignerListSearch = "Base_DesignerListSearch" + baseUnique;
let Base_FactoryListSearch = "Base_FactoryListSearch" + baseUnique;
let Base_FigureListSearch = "Base_FigureListSearch" + baseUnique;
let Base_IpListSearch = "Base_IpListSearch" + baseUnique;
let Base_MaterialListSearch = "Base_MaterialListSearch" + baseUnique;
let Base_ModelListSearch = "Base_ModelListSearch" + baseUnique;
let Base_PublisherListSearch = "Base_PublisherListSearch" + baseUnique;
let Base_ScaleListSearch = "Base_ScaleListSearch" + baseUnique;
let Base_SizeListSearch = "Base_SizeListSearch" + baseUnique;
let Base_StudioListSearch = "Base_StudioListSearch" + baseUnique;
let Base_SupplierListSearch = "Base_SupplierListSearch" + baseUnique;
let Chip_BindListSearch = "Chip_BindListSearch" + baseUnique;
let Chip_MetadataListSearch = "Chip_MetadataListSearch" + baseUnique;
let Chip_PopListSearch = "Chip_PopListSearch" + baseUnique;
let Chip_PushListSearch = "Chip_PushListSearch" + baseUnique;
let Chip_ChipListSearch = "Chip_ChipListSearch" + baseUnique;
let Community_TopicListSearch = "Community_TopicListSearch" + baseUnique;
let Core_CaseBaseListSearch = "Core_CaseBaseListSearch" + baseUnique;
let Core_SkuBaseListSearch = "Core_SkuBaseListSearch" + baseUnique;
let Core_ItemBaseListSearch = "Core_ItemBaseListSearch" + baseUnique;
let Core_CaseListSearch = "Core_CaseListSearch" + baseUnique;
let Core_CaseSortListSearch = "Core_CaseSortListSearch" + baseUnique;
let Core_ItemStoreListSearch = "Core_ItemStoreListSearch" + baseUnique;
let Core_ItemStoreSuitListSearch = "Core_ItemStoreSuitListSearch" + baseUnique;
let Core_SkuListSearch = "Core_SkuListSearch" + baseUnique;
let Core_SkuStoreListSearch = "Core_SkuStoreListSearch" + baseUnique;
let Notice_NoticeListSearch = "Notice_NoticeListSearch" + baseUnique;
let Notice_TemplateListSearch = "Notice_TemplateListSearch" + baseUnique;
let SecondaryTrade_BackExpressListSearch = "SecondaryTrade_BackExpressListSearch" + baseUnique;
let SecondaryTrade_OrderListSearch = "SecondaryTrade_OrderListSearch" + baseUnique;
let SecondaryTrade_RefundListSearch = "SecondaryTrade_RefundListSearch" + baseUnique;
let SecondaryTrade_ResellListSearch = "SecondaryTrade_ResellListSearch" + baseUnique;
let SecondaryTrade_SeekListSearch = "SecondaryTrade_SeekListSearch" + baseUnique;
let SecondaryTrade_StoreListSearch = "SecondaryTrade_StoreListSearch" + baseUnique;
let User_TransHistoryListSearch = "User_TransHistoryListSearch" + baseUnique;
let User_UserListSearch = "User_UserListSearch" + baseUnique;
let User_WalletListSearch = "User_WalletListSearch" + baseUnique;

module.exports = {
    Order_OrderListSearch,
    Order_ExpressListSearch,
    Order_BackExpressListSearch,
    Order_RefundListSearch,
    Order_TradeListSearch,
    Banner_BannerListSearch,
    App_MsgListSearch,
    Base_DesignerListSearch,
    Base_FactoryListSearch,
    Base_FigureListSearch,
    Base_IpListSearch,
    Base_MaterialListSearch,
    Base_ModelListSearch,
    Base_PublisherListSearch,
    Base_ScaleListSearch,
    Base_SizeListSearch,
    Base_StudioListSearch,
    Base_SupplierListSearch,
    Chip_BindListSearch,
    Chip_MetadataListSearch,
    Chip_PopListSearch,
    Chip_PushListSearch,
    Chip_ChipListSearch,
    Community_TopicListSearch,
    Core_CaseBaseListSearch,
    Core_SkuBaseListSearch,
    Core_ItemBaseListSearch,
    Core_CaseListSearch,
    Core_CaseSortListSearch,
    Core_ItemStoreListSearch,
    Core_ItemStoreSuitListSearch,
    Core_SkuListSearch,
    Core_SkuStoreListSearch,
    Notice_NoticeListSearch,
    Notice_TemplateListSearch,
    SecondaryTrade_BackExpressListSearch,
    SecondaryTrade_OrderListSearch,
    SecondaryTrade_RefundListSearch,
    SecondaryTrade_ResellListSearch,
    SecondaryTrade_SeekListSearch,
    SecondaryTrade_StoreListSearch,
    User_TransHistoryListSearch,
    User_UserListSearch,
    User_WalletListSearch,
};
