<template>
  <el-config-provider :locale="locale">
    <router-view />
  </el-config-provider>
</template>

<script>
  import {ElConfigProvider} from 'element-plus'
  import zhCn from 'element-plus/lib/locale/lang/zh-cn'

  export default {
    name: "app",

    components: {
      [ElConfigProvider.name]: ElConfigProvider,//添加组件
    },
    setup() {
      return {
        locale: zhCn,
      }
    },
    props: {},
    data() {
      return {};
    },
    methods: {},
  };
</script>


<style>
* {
  margin: 0;
  padding: 0;
}
.yw-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
